import { KpButton } from "@elements/index"
import { BasketResponseModel } from "@models/Basket/BasketResponseModel"
import { Checkout } from "@models/Checkout/Checkout"
import { H2, Paragraph1 } from "@styles/Global.styles"
import theme from "@styles/theme"
import { navigate } from "gatsby"
import React from "react"
import { Grid } from "semantic-ui-react"

interface Props  {
    title: string
    content: string
    checkout: Checkout
    updateIsAcceptStatement: (isAcceptStatement: boolean) => void
}

interface State {
}

class DealerStatementSection extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
    }
    
    goBack = () => {        
        if(this.props.checkout.isDealerOrder) {
            navigate('/plate-manufacture-selection');
        }
    }

    continue = () => {        
        if(this.props.checkout.isDealerOrder) {
            this.props.updateIsAcceptStatement(true);
            navigate('/personal-details');
        }
    }

    render() {
        
        return (
            <Grid>
                <Grid.Row>
                        <Grid.Column width={16}>
                            <H2>
                                {this.props.title}
                            </H2>  
                            <Paragraph1 color="#707070" dangerouslySetInnerHTML={{ __html: this.props.content}} ></Paragraph1>   
                        </Grid.Column>
                </Grid.Row>
                <Grid.Row verticalAlign='bottom'>
                    <Grid.Column width={16}>
                        <Grid reversed='mobile vertically'>
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                <KpButton id="pms-back" fullWidth="mobile" buttonType='secondary' color={theme.brand.colors.darkGrey} onClick={this.goBack}>BACK</KpButton>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={8} computer={8} textAlign='right'>
                                <KpButton id="pms-next" fullWidth="mobile" buttonType='primary' color={theme.brand.colors.blue} onClick={this.continue}>ACCEPT</KpButton>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default DealerStatementSection